<template>
  <div class="user-list">
    <normal-table
      ref="normalTable"
      :tableAction="tableAction"
      method="post"
      :url="url"
      :extra-query="{}"
      hasResetBtn
      :searchForm.sync="form"
      :page-size="200"
      :has-search-form="true"
      @select="select"
      @select-all="selectAll"
      @loaded="loaded"
    >
      <template slot="formItem">
        <el-form-item class="search-item" label="账户" >
          <el-input v-model="form.name" placeholder="账户"></el-input>
        </el-form-item>
      </template>
      <div slot="otherContent">
      
      </div>
    </normal-table>
  </div>
</template>

<script>
import NormalTable from "@/components/NormalTable/index.vue";

export default {
  data() {
    return {
      total: 100,
      form: {},
      url: "/admin/suggest/suggestList",
      tableAction: {
          label: "操作",
          prop: "handle",
          align: "center",
          formatter: ({_id, isBan}) => {
              return  (
                  <div></div>
              )
          }
      },
      selectList: []
    };
  },

  components: {
    NormalTable,
  },

  mounted() {},

  methods: {
    // 里面可以取到总数
    loaded(data) {
      // console.log(data)
    },
    select(selection) {
      this.selectList = selection
      console.log(selection)
    },
    selectAll(selection){
      this.selectList = selection
    },
      editUser(_id) {
          this.$router.push({ path: "/admin/editUser", query: { _id } }).then(() => {
              try {
                  let matchedRoute = this.$route.matched.find(route => route.path === '/admin/editUser');
                  matchedRoute.instances.default.initUserData()
              } catch (e) {
                  console.log(e)
              }
          });
      },
      gotoAddUser() {
          this.$router.push({ path: "/admin/addUser"} ).then(() => {
              try {
                  let matchedRoute = this.$route.matched.find(route => route.path === '/admin/addUser');
                  matchedRoute.instances.default.resetForm()
              } catch (e) {
                  console.log(e)
              }
          });
      },
    ban(_ids) {
        _ids = _ids.join(',')
        this.$http.post('/admin/user/ban', {_ids: _ids}).then(res => {
            if (res.code === 0) {
                this.$message({
                    message: "成功",
                    type: "success",
                });
                this.$refs.normalTable.getList()
            }
        })
    },
    unBan(_ids) {
        _ids = _ids.join(',')
        this.$http.post('/admin/user/unBan', {_ids: _ids}).then(res => {
            if (res.code === 0) {
                this.$message({
                    message: "成功",
                    type: "success",
                });
                this.$refs.normalTable.getList()
            }
        })
    },
    banBatch(list) {
        let _ids = []
        list.forEach(it => {
            _ids.push(it._id)
        })
        this.ban(_ids)
    },
      unBanBatch(list) {
          let _ids = []
          list.forEach(it => {
              _ids.push(it._id)
          })
          this.unBan(_ids)
      },
      resetForm() {
        this.form = {}
      },
  },
};
</script>
<style lang="scss" scoped>
.user-list {
  .total{
    font-size: 14px;
    color: #333;
    text-align: right;
    padding-right: 30px;
  }
}
</style>

<style>
.el-message-box {
    max-width: 100% !important;
}
</style>